<template>
    <v-dialog
        v-model='dialog'
        max-width='500'
    >
        <v-card>
            <v-form v-model='formValid' ref='formulario'>
                <v-toolbar color='primary' dark dense>
                    <v-toolbar-title>
                        Nuevo archivo
                    </v-toolbar-title>

                    <v-spacer/>

                    <v-btn
                        icon
                        @click='dialog=false'
                    >
                        <v-icon >
                            mdi-close-box
                        </v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <v-select
                        v-model="nuevo.tipoDocumento"
                        :items="tiposDocumentos"
                        @click="cargaTipoDocumento"
                        label="Grupo de documento"
                        rounded
                        filled
                        dense
                        item-text="tipoArchivo"
                        item-value="id"
                        :rules="[value => !!value || 'Requerido.']"
                    />

                    <v-expand-transition>
                        <v-text-field
                            v-if="nuevo.tipoDocumento==99"
                            v-model="nuevo.otroDocumento"
                            label="Otro gupo (descripcion)"
                            rounded
                            filled
                            dense
                            :rules="[value => !!value || 'Requerido.']"
                        />

                    </v-expand-transition>

                    <v-text-field
                        v-model="nuevo.nombreDocumento"
                        label="Nombre del documento"
                        rounded
                        filled
                        dense
                        :rules="[value => !!value || 'Requerido.']"
                    />

                    <v-file-input
                        label="Achivo"
                        v-model="nuevo.archivoDatos"
                        filled
                        prepend-icon="mdi-file-upload"
                        rounded
                        dense
                        show-size
                        :rules="[value => !!value || 'Requerido.']"
                    />


                    <v-checkbox
                        v-model="nuevo.caduca"
                        label="caduca"
                    />
                    <v-expand-transition>
                        <div class="flex row ma-1" v-if="nuevo.caduca">
                            <v-menu
                                bottom
                                left
                                min-width="auto"
                            >
                                <template v-slot:activator="{on}">
                                    <v-icon v-on="on" class="mb-7 mr-1">mdi-calendar</v-icon>
                                </template>
                                <v-date-picker
                                    v-model="fechaCadTmp"
                                    no-title
                                    scrollable
                                />
                            </v-menu>
                                <v-text-field
                                    dense
                                    v-model="nuevo.fechaCaducidad"
                                    label="Fecha de caducidad"
                                    persistent-hint
                                    rounded
                                    filled
                                    :rules='[
                                        value => !!value || "Requiere fecha de instalación",
                                        value => /^([0-2][0-9]|3[0-1])(-)(0[1-9]|1[0-2])\2(\d{4})$/.test(value) || "Formato debe ser tipo "+moment(new Date()).format("DD-MM-YYYY"),
                                    ]'
                                />
                        </div>
                    </v-expand-transition>


                    <v-textarea
                        label="Descripción"
                        v-model="nuevo.observaciones"
                        rounded
                        filled
                        dense
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>

                    <!-- El botón para CANCELAR -->
                    <v-btn
                        text
                        color='error'
                        @click='dialog=false'
                    >
                        Cancelar
                    </v-btn>

                    <!-- El botón para GUARDAR -->
                    <v-btn
                        color='success'
                        :disabled='!formValid'
                        :loading='loadingBtn'
                        @click='guarda()'
                    >
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props:{
        dialogNuevoDoc:Boolean,
    },
    data:()=>({
        formValid:false,
        loadingBtn:false,
        nuevo:{},
        tiposDocumentos:[],
        menuFechaCad:false,
        fechaCadTmp:'',
    }),
    methods:{

        async cargaTipoDocumento(){
            try{
                let req = await this.$http({
                    url: '/catalogos/adminTipoDocumento',
                    method:'GET',
                })
                this.tiposDocumentos=req.data

            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async guarda(){
            //console.log(this.datosTrabajador)
            // Procesa los datos del usuario
            // Procesa el archivo
            let formData = new FormData()
            formData.append('archivo', this.nuevo.archivoDatos)
            this.nuevo.activo=true
            if (!this.nuevo.caduca){
                this.nuevo.fechaCaducidad = null
            }
            try{
                let req = await this.$http({
                    url: '/admin/guardaArchivo',
                    method:'POST',
                    params:this.nuevo,
                    data:formData,
                    headers: {'Content-Type': 'multipart/form-data' },
                })
                if (req.data.estado){
                    this.$emit("guardado")
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Guardado',
                        text: 'El archivo se ha guardado existosamente.',
                    })
                    this.dialog = false
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                this.loadingBtn = false
                //console.log(req.data)
            }catch(err){
                this.loadingBtn = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    computed:{
        dialog:{
            get(){
                return this.dialogNuevoDoc
            },
            set(val){
                this.$emit('update:dialogNuevoDoc',val)
            },
        },
    },
    watch:{
        fechaCadTmp(val){
            if (val){
                this.nuevo.fechaCaducidad=this.moment(val).format('DD-MM-YYYY')
            }
        },
        dialog(v){
            if (!v){
                this.nuevo.tipoDocumento  = null
                this.$refs.formulario.reset()
            }

        },
    },
}
</script>

<style>
</style>